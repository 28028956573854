//prep the doc

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}

p, a {
    font-family: sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Open Sans', sans-serif;
}

h2, h3 {
    margin: 1em 0 0.5em 0;
}

@import "pages_typography";
@import "colors";
@import "navbar";
@import "footer";
@import "pricing";
@import "contact";
@import "cta";

.hero_section {
    height: 50vh;
    margin: 0;
    background-image: $hero-background-image;
    position: relative;
    z-index: -5;
    h1 {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        position: absolute;
        padding: 0 0.25em;
        margin: 0;
        margin-right: 4px;
        bottom: 20px;
        font-size: 2.4rem;
        background-color: $hero_title_background_color;
        color: $hero-title-text-color;
        font-weight: bold;
    }
}


main {
    margin: 2em auto;
    max-width: 700px;
    p {
        margin: 0.5em 0;
    }
}

//special styling for the pricing page since the cards are nested in main

main.pricing {
    margin: 0 1em;
    max-width: 100vw;
    section.about {
        margin: 2em auto;
        max-width: 700px;
    }
}

//special styling for details dropdown on pricing page ****NOT CURRENTLY DISPLAYED****

#details_dropdown {display: none;}
details {
    padding: 0.25em 0;
    border: 2px solid black;
    border-bottom: none;
    cursor: pointer;
}

details:hover {
    background-color: rgba(0, 0, 255, 0.308);
}
details:last-of-type {
    border-bottom: 2px solid black;
}

//media queries

@media (max-width: 750px) {
    main {
        margin: 2em 1em;
    }
}

@media (min-width: 700px) {
    .hero_section h1 {
        font-size: 4rem;
        max-width: 95vw;
    }
}

