// site colors
$shadow-left: -5px 5px 5px rgba(184, 184, 184, 0.877);
$shadow-bottom: 0 10px 15px 0 rgba(0,0,0,.16),0 0 0 1px rgba(0,0,0,.06);
$index-hero-background: linear-gradient(0deg, rgba(59,77,162,1) 20%, rgba(117,178,255,1) 100%);
$index-hero-after-psuedo: rgba(59,77,162,1);
$stats-callout-background-color: rgb(255, 255, 255);
$stats-callout-shadow: 0 10px 15px 0 rgba(0,0,0,.16),0 0 0 1px rgba(0,0,0,.06);
$services-box-background-color: #475cc4;
$services-box-shadow: -5px 5px 5px rgba(184, 184, 184, 0.877);
$services-box-text-color: white;
$info-image-background:  #8abfff6e;

// pages colors
$hero-background-image: url("/assets/images/background-hero-image.png");
$hero-title-background-color: #3b1d5a;
$hero-title-text-color: white;