html {
    font-size: 18px;
    line-height: 1.5rem;
}

h1 {
    font-size: 2rem;
    line-height: 1.25;
  }
  h2 {
    font-size: 1.67rem;
    line-height: 1.15;
  }
  h3 {
    font-size: 1.375rem;
    line-height: 1.13636364;
  }
  h4 {
    font-size: 1.125rem;
    line-height: 1.11111111;
  }
  h5 {
    font-size: 1rem;
    line-height: 1.25;
  }
  h6 {
    font-size: 0.75rem;
    line-height: 1.25;
  }

@media (min-width: 650px) {
    body {
        font-size: 1.12rem;
        line-height: 1.5rem;
    }
}