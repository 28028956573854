$button-hover: #428000;
$shadow-left: -5px 5px 5px rgba(184, 184, 184, 0.877);
$shadow-bottom: 0 10px 15px 0 rgba(0,0,0,.16),0 0 0 1px rgba(0,0,0,.06);
@mixin hover {
    background-color: $button-hover;
    color: white;
    box-shadow: $shadow-bottom;
    border-color: $button-hover;
}

.CTA_1 {
    display: flex;
    flex-flow: row wrap;
    padding: 3em 2em;
    justify-content: center;
    align-items: center;
    text-align: center;
    .CTA_inner_content {
        margin: 0;
    }
    p {
        float: left;
        color: rgb(87, 87, 87);
        margin-top: 0;
        margin-bottom: 0;
    }
    h2 {
        margin-top: 0;
        margin-bottom: 0.5em;
    }
}

.CTA_button_wrapper {
    margin: 0.5em 1em 2em 1em;
    a {
        display: inline-block;
        border-radius: 10px;
        background: #428000;
        text-decoration: none;
        color: white;
        padding: 10px 25px;
        transition: transform .2s;
        &:hover {
            @include hover;
            transform: scale(1.1);
        }
    }
}

.clear_CTA_button_wrapper {
    margin: 0.5em 1em 2em 1em;
    a {
        border: 2px solid #abdf75;
        display: inline-block;
        border-radius: 10px;
        background: white;
        text-decoration: none;
        color: black;
        padding: 10px 25px;
        transition: transform .2s;
        &:hover {
            @include hover;
        }
    }
}

.inline_CTA, .CTA {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #8abfffaf;
    box-shadow: $shadow-bottom;
    justify-content: center;
    border-radius: 10px;
    margin-top: 1em;
    .CTAp {
        margin: 1em;
        margin-top: 1em;
        font-size: 1.25rem;
        text-align: center;
    }
}