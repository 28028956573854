footer {
    background-color: #475cc4;
}

.links{
    display: flex;
    flex-flow: row wrap;
    text-align: center;
    justify-content: center;
    ul {
        margin: 0;
        padding: 0.5em 4em;
    }
    lh {
        font-weight: bold;
        color: white;
    }
    li {
        list-style: none;
    }
    a{
        font-size: 10pt;
        color: white;
    }
}

footer p {
    display: block;
    color: white;
    margin: 0 auto 0 auto;
    font-size: small;
    text-align: center;
}